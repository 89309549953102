import React from 'react';
import { styled } from 'styletron-react';
import Layout from '../components/layout';
import githubIcon from '../assets/github-icon.svg';
import linkedinIcon from '../assets/linkedin-icon.svg';
import twitterIcon from '../assets/twitter-icon.svg';
import discordIcon from '../assets/discord-icon.svg';

const Headline = styled('h1', {
  fontSize: '4rem',
  margin: '5rem 0',
  color: 'rgba(0,0,0,.6)',
  fontWeight: 'bold',
});

const Icon = styled('img', {
  width: '1rem',
  height: '1rem',
  marginBottom: '-.3rem',
  marginRight: '.5rem',
});

const Div = styled('div', {
  marginBottom: '.8rem',
});

export default function About() {
  return (
    <Layout>
      <Headline>Hello.</Headline>
      <p>
        I&apos;m Daniel Emod Kovacs, a software engineer from Budapest. During
        the day you&apos;ll find me writing code for tech companies, at nights I
        teach, contribute to open source projects and work on my pet projects.
      </p>
      <h2>Social accounts</h2>
      <p>I can be found on all of the following social media accounts:</p>
      <Div>
        <Icon src={githubIcon} alt="GitHub" />
        <a href="https://github.com/danielkov">danielkov</a>
      </Div>
      <Div>
        <Icon src={discordIcon} alt="Discord" />
        <a href="https://discord.gg/eqYqU3V">danielkov</a>
      </Div>
      <Div>
        <Icon src={linkedinIcon} alt="LinkedIn" />
        <a href="https://www.linkedin.com/in/kovacsemod/">kovacsemod</a>
      </Div>
      <Div>
        <Icon src={twitterIcon} alt="Twitter" />
        <a href="https://twitter.com/DanyKovacs">@DanyKovacs</a>
      </Div>
      <h2>Enterprise projects</h2>
      <p>
        Below is a list of companies / products I worked with in the past few
        years:
      </p>
      <ul>
        <li>
          <a href="https://www.ilovegyros.hu/">I Love Gyros</a>
        </li>
        <li>
          <a href="https://www.telekom.hu/">Telekom Hungary</a>
        </li>
        <li>
          <a href="https://www.gramexdrinks.com/">Gramex Drinks</a>
        </li>
        <li>
          <a href="https://www.creditexpress.com/">Credit Express Group</a>
        </li>
        <li>
          <a href="https://www.posta.hu/">Hungarian Post</a>
        </li>
        <li>
          <a href="https://www.dpd.fr/">DPD France</a>
        </li>
      </ul>
      <h2>Open Source</h2>
      <p>
        I &hearts; Open Source. Here are some of the projects I&apos;ve created
        or contributed to recently:
      </p>
      <ul>
        <li>
          <a href="https://github.com/danielkov/gin-helmet">
            <code>gin-helmet</code>
          </a>
        </li>
        <li>
          <a href="https://github.com/gatsbyjs/gatsby">
            <code>Gatsby</code>
          </a>
        </li>
        <li>
          <a href="https://github.com/rstacruz/cheatsheets">
            <code>Dev Cheatsheets</code>
          </a>
        </li>
        <li>
          <a href="https://www.npmjs.com/package/react-architect">
            <code>react-architect</code>
          </a>
        </li>
        <li>
          <a href="https://www.npmjs.com/package/evs">
            <code>evs</code>
          </a>
        </li>
        <li>
          <a href="https://www.npmjs.com/package/restponse">
            <code>restponse</code>
          </a>
        </li>
      </ul>
    </Layout>
  );
}
